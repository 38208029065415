import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
  })
  .required();

const Login = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>email</p>
      <input  {...register("email")} />
      <p>{errors.email?.message}</p>
      <p>password</p>
      <input type="password" {...register("password")} />
      <p>{errors.password?.message}</p>
      <input id="kirjaudu" type="submit" />
    </form>
  );
};

export default Login;
